"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalUser = exports.User = void 0;
var utils_1 = require("../utils/utils");
var Storage_1 = __importStar(require("./Storage"));
var __1 = require("../");
var moduleTypes_1 = require("../modules/consent/moduleTypes");
var log_1 = require("../utils/log");
var utils_2 = require("../utils/utils");
var User = /** @class */ (function () {
    function User() {
        this._uuid = '';
        this._uuid_storage_name = "".concat(Storage_1.STORAGE_PREFIX, "uuid");
        this._uhem = {
            'ip': '',
            'source': '',
            'source_type': '',
            'hashes': {
                'SHA256': '',
                'SHA1': '',
                'MD5': ''
            }
        };
        this._uhem_storage_name = "".concat(Storage_1.STORAGE_PREFIX, "uhem");
        this.new_user = false;
        this.acct_type = 'guest';
        this.hashedEmail = {
            'ip': '',
            'source': '',
            'source_type': '',
            'hashes': {
                'SHA256': '',
                'SHA1': '',
                'MD5': ''
            }
        };
        this.ipv4 = '';
        this.ipv6 = '';
        this._storageObj = { 'componentType': moduleTypes_1.MODULE_TYPE_CORE };
        this.getUUID();
        this.saveUUID();
    }
    User.prototype.getUUID = function () {
        // if uuid is already set, return it
        if (this._uuid)
            return this._uuid;
        // get uuid from local storage
        this._uuid = Storage_1.default.getItemFromStorage(this._uuid_storage_name, this._storageObj);
        // if uuid is not set, generate new one
        if (!this._uuid) {
            this._uuid = (0, utils_1.generateUUID)();
            // set new user flag
            this.new_user = true;
        }
        // get user hashed email
        this.getUserHashedEmail();
        // return user id;
        return this._uuid;
    };
    User.prototype.saveUUID = function () {
        var _a;
        // if uuid is not set, return false
        if (!this._uuid)
            return false;
        var maxAge = 60 * 60 * 24 * 365 * 1; // 1 years
        // set storage options
        var storageOptions = {
            'maxAge': maxAge, // expire in 1 year
            'expires': '',
            'path': '/', // root path
            'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname), // root domain
            'sameSite': 'None', // Allow cross-site cookies
            'secure': 1 // cookie will only be sent over SSL
        };
        // save id to local storage
        Storage_1.default.setItemInStorage(this._uuid_storage_name, this._uuid, this._storageObj, storageOptions);
        return true;
    };
    User.prototype.getUserHashedEmail = function () {
        // get user hashed email from local storage
        var uhem = (0, utils_2.safeJsonParse)(Storage_1.default.getItemFromStorage(this._uhem_storage_name, this._storageObj));
        if (uhem && uhem.source && uhem.hashes) {
            this._uhem = uhem;
            this.setHashedEmail(this._uhem.source, this._uhem.hashes, '1p');
        }
        // return users hashed email
        return this._uhem;
    };
    User.prototype.saveUserHashedEmail = function () {
        var _a;
        var maxAge = 60 * 60 * 24 * 365 * 1; // 1 years
        // set storage options
        var storageOptions = {
            'maxAge': maxAge, // expire in 1 year
            'expires': '',
            'path': '/', // root path
            'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname), // root domain
            'sameSite': 'None', // Allow cross-site cookies
            'secure': 1 // cookie will only be sent over SSL
        };
        // save id to local storage
        Storage_1.default.setItemInStorage(this._uhem_storage_name, JSON.stringify(this._uhem), this._storageObj, storageOptions);
    };
    User.prototype.setAcctType = function () {
        var _a;
        try {
            // get account type from global tag
            var acct_type = (_a = __1.BCLighthouseTag === null || __1.BCLighthouseTag === void 0 ? void 0 : __1.BCLighthouseTag.metadata) === null || _a === void 0 ? void 0 : _a.acct_type;
            // possible account types
            var acct_types = ['guest', 'logged_in', 'paid', 'subscriber', 'free'];
            // if account type is valid, set it
            if (acct_type && acct_types.includes(acct_type)) {
                this.acct_type = acct_type;
            }
        }
        catch (e) {
            console.error('BigCrunch: Error setting account type:', e);
        }
    };
    User.prototype.setIp = function (ip) {
        if (ip) {
            // check if ip is ipv4 or ipv6
            var ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            var ipv6Regex = /([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|::([0-9a-fA-F]{1,4}:){1,7}/;
            if (ipv4Regex.test(ip)) {
                (0, log_1.logInfo)('BigCrunch: Setting User IPV4:', ip);
                this.ipv4 = ip;
            }
            else if (ipv6Regex.test(ip)) {
                (0, log_1.logInfo)('BigCrunch: Setting User IPV6:', ip);
                this.ipv6 = ip;
            }
        }
        if ((this.ipv4 || this.ipv6) && !this.hashedEmail.ip) {
            // set ip in hashed email if not already set
            this._uhem.ip = this.ipv4 || this.ipv6 || '';
            this.hashedEmail.ip = this._uhem.ip;
            // save hashed email to local storage
            this.saveUserHashedEmail();
        }
    };
    User.prototype.setHashedEmail = function (source, hems, source_type) {
        var _a, _b, _c, _d;
        try {
            if (!source_type ||
                !source ||
                !hems ||
                (!hems['SHA256'] &&
                    !hems['SHA1'] &&
                    !hems['MD5']))
                return;
            var hem_source_priority = ['3p', '1p'];
            // check hem priority
            if (!((_a = this.hashedEmail) === null || _a === void 0 ? void 0 : _a.source_type) ||
                hem_source_priority.indexOf(source_type) > hem_source_priority.indexOf(this.hashedEmail.source_type)) {
                hems = {
                    'SHA256': hems['SHA256'] || ((_b = this === null || this === void 0 ? void 0 : this.hashedEmail) === null || _b === void 0 ? void 0 : _b.hashes['SHA256']) || '',
                    'SHA1': hems['SHA1'] || ((_c = this === null || this === void 0 ? void 0 : this.hashedEmail) === null || _c === void 0 ? void 0 : _c.hashes['SHA1']) || '',
                    'MD5': hems['MD5'] || ((_d = this === null || this === void 0 ? void 0 : this.hashedEmail) === null || _d === void 0 ? void 0 : _d.hashes['MD5']) || ''
                };
                this.hashedEmail = {
                    'ip': this.ipv4 || this.ipv6 || '',
                    'source_type': source_type,
                    'source': source,
                    'hashes': hems
                };
                (0, log_1.logInfo)('BigCrunch: Setting Hashed Email:', this.hashedEmail);
                // save hashed email to local storage
                if (source_type == '1p') {
                    this._uhem = this.hashedEmail;
                    this.saveUserHashedEmail();
                }
                // fire custom event
                var event_1 = new CustomEvent('BcUserHasedEmail', { detail: (0, utils_2.deepCopy)(this.hashedEmail) });
                document.dispatchEvent(event_1);
            }
        }
        catch (e) {
            (0, log_1.logError)('BigCrunch: Error setting hashed email:', e);
        }
    };
    User.prototype.checkHasHashedEmail = function () {
        return this.hashedEmail && this.hashedEmail.hashes && (this.hashedEmail.hashes.SHA256 || this.hashedEmail.hashes.SHA1 || this.hashedEmail.hashes.MD5);
    };
    return User;
}());
exports.User = User;
exports.globalUser = new User();
