"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalSession = exports.Session = void 0;
var moduleTypes_1 = require("../modules/consent/moduleTypes");
var utils_1 = require("../utils/utils");
var Storage_1 = __importStar(require("./Storage"));
var Analytics_1 = require("./Analytics");
var User_1 = require("./User");
var utils_2 = require("../utils/utils");
var SESSION_STORAGE_NAME = "".concat(Storage_1.STORAGE_PREFIX, "session_id");
var SESSION_STORAGE_OPTIONS = {
    'maxAge': 60 * 30, // expire in 30 minutes
    'expires': '', // expire at end of session
    'path': '/', // root path
    'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname), // root domain
    'sameSite': 'None', // Allow cross-site cookies
    'secure': 1 // cookie will only be sent over SSL
};
var SESSION_STORAGE_OBJ = { 'componentType': moduleTypes_1.MODULE_TYPE_CORE };
var Session = /** @class */ (function () {
    function Session() {
        this.session_analytics_logged = false;
        this.data = {
            new_user: false,
            revenue: 0,
            floors: {},
            depth: 0,
            utm: {
                source: null,
                medium: null,
                campaign: null,
                term: null,
                content: null
            },
            gclid: '',
            fbclid: '',
            referrer: '',
            source: '',
            medium: ''
        };
    }
    Session.prototype.startNewSession = function () {
        // delete session data
        this.deleteSessionData();
        // reset session depth
        this.data.depth = 1;
        // generate new id
        this.id = (0, utils_1.generateUUID)();
        // set session data in storage
        this.data.utm = this.getUtmData();
        // set session source and medium
        var _a = (0, utils_1.determineSessionSourceMedium)(window.location.href, document.referrer), source = _a.source, medium = _a.medium;
        this.data.source = source;
        this.data.medium = medium;
        // set clid data
        var clids = this.getClids();
        this.data.gclid = clids.gclid || '';
        this.data.fbclid = clids.fbclid || '';
        // set referrer
        this.data.referrer = this.getReferrer();
        // set new user flag
        this.data.new_user = User_1.globalUser.new_user;
        // reset revenue
        this.data.revenue = 0;
        // reset session analytics logged
        this.session_analytics_logged = false;
        // set session data in storage
        this.setSessionData();
    };
    /**
     * @returns {string}
     * @description Get session id from storage
     */
    Session.prototype.getSessionId = function () {
        // get session id from storage
        this.id = Storage_1.default.getCookie(SESSION_STORAGE_NAME, SESSION_STORAGE_OBJ);
        // get stored session data
        this.getSessionData();
        // if session id is not set or utm has changed
        if (!this.id) {
            this.startNewSession();
        }
        else {
            // check if utms/clids changed
            if (this.checkReferrerChange()) {
                // set utm data
                this.data.utm = this.getUtmData();
                // set session source and medium
                var _a = (0, utils_1.determineSessionSourceMedium)(window.location.href, document.referrer), source = _a.source, medium = _a.medium;
                this.data.source = source;
                this.data.medium = medium;
                // set clid data
                var clids = this.getClids();
                this.data.gclid = clids.gclid || '';
                this.data.fbclid = clids.fbclid || '';
                // set referrer
                this.data.referrer = this.getReferrer();
            }
            this.incrementDepth();
            this.session_analytics_logged = true;
        }
        // set session id in storage
        Storage_1.default.setCookie(SESSION_STORAGE_NAME, this.id, SESSION_STORAGE_OBJ, SESSION_STORAGE_OPTIONS);
        return true;
    };
    // function that checks if referrer has changed from the current referrer
    // if it has, then it will start a new session
    Session.prototype.checkReferrerChange = function () {
        // get current referrer
        var currentReferrer = this.data.referrer || '';
        // get referrer from url
        var referrer = (0, utils_1.getReferrer)();
        // if referrer has changed
        if (referrer && // if referrer exists
            referrer.hostname !== (0, utils_1.getDomain)() && // if referrer is not current domain
            referrer.href !== currentReferrer // if referrer is not current referrer
        ) {
            return true;
        }
        return false;
    };
    Session.prototype.getReferrer = function () {
        var referrer = (0, utils_1.getReferrer)();
        return referrer && referrer.hostname !== (0, utils_1.getDomain)() ? referrer.href : '';
    };
    // function that checks if session data's utm has changed from the current utm
    // if it has, then it will start a new session
    Session.prototype.checkUtmChange = function () {
        // get current utm data
        var currentUtm = this.data.utm;
        // get utm data from url
        var utm = this.getUtmData();
        // loop through utm keys
        for (var key in utm) {
            // if utm key has changed
            if (utm[key] && utm[key] !== currentUtm[key]) {
                return true;
            }
        }
        return false;
    };
    Session.prototype.getUtmData = function () {
        var utm = {
            source: '',
            medium: '',
            campaign: '',
            term: '',
            content: ''
        };
        // get utm data from url
        var url = (0, utils_1.getURL)();
        if (url) {
            utm = {
                source: url.searchParams.get('utm_source') || '',
                medium: url.searchParams.get('utm_medium') || '',
                campaign: url.searchParams.get('utm_campaign') || '',
                term: url.searchParams.get('utm_term') || '',
                content: url.searchParams.get('utm_content') || ''
            };
        }
        return utm;
    };
    Session.prototype.checkClidChange = function () {
        // get current clid data
        var currentClids = {
            gclid: this.data.gclid,
            fbclid: this.data.fbclid
        };
        // get clid data from url
        var clids = this.getClids();
        // loop through clid keys
        for (var key in clids) {
            // if clid key has changed
            if (clids[key] && clids[key] !== currentClids[key]) {
                return true;
            }
        }
        return false;
    };
    Session.prototype.getClids = function () {
        var url = (0, utils_1.getURL)();
        if (url) {
            return {
                gclid: url.searchParams.get('gclid') || '',
                fbclid: url.searchParams.get('fbclid') || ''
            };
        }
        return {};
    };
    Session.prototype.incrementRevenue = function (revenue) {
        // set revenue
        this.data.revenue += revenue;
        // set session data in storage
        this.setSessionData();
    };
    Session.prototype.incrementDepth = function () {
        // increment depth
        this.data.depth++;
        // set session data in storage
        this.setSessionData();
    };
    Session.prototype.getSessionData = function () {
        if (this.id) {
            // Get session data from storage
            this.data = (0, utils_2.deepMerge)(this.data, (0, utils_1.safeJsonParse)(Storage_1.default.getItemFromStorage(this.id, SESSION_STORAGE_OBJ)) || {}, { customMerge: utils_2.customMerge });
        }
    };
    Session.prototype.setSessionData = function () {
        if (this.id) {
            // Set session data in storage
            Storage_1.default.setItemInStorage(this.id, JSON.stringify(this.data), SESSION_STORAGE_OBJ, SESSION_STORAGE_OPTIONS);
            // send session data to server
            if (!exports.globalSession.session_analytics_logged) {
                Analytics_1.globalAnalytics.sendSessionPageAnalytics();
            }
        }
    };
    Session.prototype.deleteSessionData = function () {
        // Delete session data from storage
        Storage_1.default.removeDataFromLocalStorage(this.id, SESSION_STORAGE_OBJ);
    };
    return Session;
}());
exports.Session = Session;
exports.globalSession = new Session();
