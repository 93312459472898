"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GDPR_GVLIDS = void 0;
exports.gvlidRegistry = gvlidRegistry;
function gvlidRegistry() {
    var registry = {};
    var flat = {};
    var none = {};
    return {
        /**
         * Register a module's GVL ID.
         * @param {string} moduleType defined in `moduleTypes.ts`
         * @param {string} moduleName
         * @param {number} gvlid
         */
        register: function (moduleType, moduleName, gvlid) {
            if (gvlid) {
                (registry[moduleName] = registry[moduleName] || {})[moduleType] = gvlid;
                if (Object.prototype.hasOwnProperty.call(flat, moduleName)) {
                    if (flat[moduleName] !== gvlid)
                        flat[moduleName] = none;
                }
                else {
                    flat[moduleName] = gvlid;
                }
            }
        },
        /**
         * Get a module's GVL ID(s).
         *
         * @param {string} moduleName
         * @return {{modules: {[moduleType]: number}, gvlid?: number}} an object where:
         *   `modules` is a map from module type to that module's GVL ID;
         *   `gvlid` is the single GVL ID for this family of modules (only defined
         *   if all modules with this name declared the same ID).
         */
        get: function (moduleName) {
            var result = { modules: registry[moduleName] || {}, gvlid: undefined };
            if (Object.prototype.hasOwnProperty.call(flat, moduleName) && flat[moduleName] !== none) {
                result.gvlid = flat[moduleName];
            }
            return result;
        }
    };
}
exports.GDPR_GVLIDS = gvlidRegistry();
