"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var log_1 = require("../utils/log");
var __1 = require("..");
var utils_1 = require("../utils/utils");
var AuctionHandler_1 = require("./AuctionHandler");
var Bid_1 = require("./Bid");
var ViewabilityTracker_1 = require("./ViewabilityTracker");
var Page_1 = require("./Page");
var VideoInstance_1 = require("./VideoInstance");
var Floors_1 = require("./Floors");
var Slot = /** @class */ (function () {
    /**
     * @param slotConfig
     * @description Slot constructor
     */
    function Slot(slotConfig) {
        this.sizes = {};
        this.min_width = 0;
        this.min_height = 0;
        this.floor = 0.10;
        this.default_floor = 0.10;
        this.bids = [];
        this.mediaTypes = {};
        this.enabled = true;
        this.auction_finished = false;
        this.ad_injected = false;
        this.displayed = false;
        this.displayed_ts = 0;
        this.auction_min_interval = 15000;
        this.auction_interval_met = true;
        this.auction_no_bid_count = 0;
        this.instance_number = 0;
        this.dynamic_inview = {
            enabled: false,
            css_class: '',
            sticky: false
        };
        this.refresh = {
            force: false,
            enabled: false,
            interval: 30000,
            interval_met: false,
            interval_timer: null,
            current_interval: 0,
            inview_enabled: false,
            inview_interval: 5000,
            inview_interval_met: false,
            inview_interval_timer: null,
            current_inview_interval: 0,
            count: 0,
            max: 30,
            unfilled_count: 0,
            unfilled_max: 3
        };
        this.viewability = {
            enabled: true,
            inview: null,
            active_tab: true,
            entered_inview_ts: 0,
            time_inview_since_displayed: 0
        };
        this.lazyload = {
            enabled: false,
            fetch_threshold: 100,
            in_fetch_range: false,
            display_threshold: 50,
            in_display_range: false
        };
        this.auto_delete = {
            enabled: false,
            threshold: 200
        };
        this.gam = {
            name: '',
            code: '',
            id: '',
            path: ''
        };
        this.adserver = {
            auction_started: false,
            auction_finished: false
        };
        this.prebid = {
            enabled: false,
            bid_caching: false,
            adUnitCode: '',
            bidderIdGroups: [],
            bidders: {},
            bids: {},
            header_winning_bid: {
                adId: '',
                bidder: '',
                cpm: 0,
                currency: '',
                height: 0,
                width: 0,
                mediaType: '',
                ad: '',
                nurl: '',
                burl: '',
                lurl: '',
                auctionId: '',
                floorData: {
                    floorValue: 0
                }
            },
            header_auction_started: false,
            header_auction_finished: false
        };
        this.amazon_aps = {
            enabled: false,
            winning_bid: null,
            auction_started: false,
            auction_finished: false
        };
        this.config = slotConfig;
        this.id = slotConfig['id'];
        this.name = slotConfig['name'];
        this.default_floor = slotConfig['floor'] || __1.globalSettings.floor.default || 0;
        this.floor = this.getSetFloor();
        this.sticky = slotConfig['sticky'];
        this.outstream = slotConfig['outstream'];
        this.mediaTypes = slotConfig['mediaTypes'];
        this.instance_number = slotConfig['instance_number'] || 0;
        this.slot_id = "".concat((0, utils_1.normalizeString)(this.name), "-").concat(this.instance_number);
        this.div_id = "bigcrunch-unit-".concat(this.slot_id);
        this.css_selector = slotConfig['css_selector'] || '';
        this.referenceElement = slotConfig['referenceElement'];
        Object.assign(this.dynamic_inview, slotConfig['dynamic_inview']);
        Object.assign(this.lazyload, slotConfig['lazyload']);
        Object.assign(this.refresh, slotConfig['refresh']);
        Object.assign(this.auto_delete, slotConfig['auto_delete']);
        Object.assign(this.gam, slotConfig['gam']);
        Object.assign(this.prebid, slotConfig['prebid']);
        Object.assign(this.amazon_aps, slotConfig['amazon_aps']);
    }
    Slot.prototype.buildSlot = function () {
        // check if slot is enabled
        if (!this.enabled)
            return false;
        // set gpid for slot
        this.gpid = this.createGpid();
        // Set sizes for slot
        this.sizes = this.setSizes();
        // Set min height for slot
        this.getMinHeight();
        // Insert slot into dom
        if (!this.insertSlot()) {
            (0, log_1.logWarn)("Unable to insert slot into dom.", this.slot_id);
            return false;
        }
        // set min height of element to min_height
        this.element.style.minHeight = "".concat(this.min_height, "px");
        // fire custom event that slot has been built
        var event = new CustomEvent('BcSlotBuilt', { detail: this });
        document.dispatchEvent(event);
        // set viewability tracker
        this.setViewabilityTracker();
        // check lazyload is enabled
        if (this.lazyload.enabled) {
            this.setLazyLoadTrackers();
        }
        else {
            this.lazyload.in_fetch_range = true;
            this.lazyload.in_display_range = true;
            this.sendNewAuction();
        }
        // set auto delete tracker
        this.setAutoDeleteTracker();
        return true;
    };
    /**
     * @description Create gpid for slot
     * @returns string
     */
    Slot.prototype.createGpid = function () {
        /* if(globalSettings.adserver === 'googletag' && this.gam.path) {
            return `${this.gam.path}#${this.instance_number + 1}`;
        } else {
            return `${this.slot_id}`;
        } */
        return this.name;
    };
    // get data-targeting property from slots div id element and parse it as JSON
    Slot.prototype.getTargetingFromElem = function () {
        try {
            var targeting = this.referenceElement.getAttribute('data-targeting');
            if (targeting) {
                return JSON.parse(targeting);
            }
        }
        catch (error) {
            (0, log_1.logWarn)("Unable to parse data-targeting attribute.", this.slot_id);
        }
        return {};
    };
    Slot.prototype.setViewabilityTracker = function () {
        var _this = this;
        if (this.viewability.enabled) {
            // track element viewability
            ViewabilityTracker_1.viewabilityTracker.trackElement(this.element, function (entry) {
                _this.handleViewabilityChange(entry.isIntersecting);
            });
        }
    };
    Slot.prototype.removeViewabilityTracker = function () {
        if (this.viewability.enabled) {
            // track element viewability
            ViewabilityTracker_1.viewabilityTracker.untrackElement(this.element);
        }
    };
    Slot.prototype.setLazyLoadTrackers = function () {
        var _this = this;
        if (this.lazyload.enabled) {
            // set lazyload fetch threshold if it is less than display threshold
            if (this.lazyload.fetch_threshold < this.lazyload.display_threshold) {
                this.lazyload.fetch_threshold = this.lazyload.display_threshold;
            }
            // set lazyload fetch observer
            var lazyload_fetch_options = {
                root: null,
                rootMargin: this.lazyload.fetch_threshold + '%',
                threshold: 0.5
            };
            this.lazyloadFetchObserver = new ViewabilityTracker_1.ViewabilityTracker(false, lazyload_fetch_options);
            this.lazyloadFetchObserver.trackElement(this.element, function (entry) {
                if (_this.lazyload.fetch_threshold !== _this.lazyload.display_threshold) {
                    _this.handleLazyLoadChange('fetch', entry);
                }
                else {
                    _this.handleLazyLoadChange('fetch', entry);
                    _this.handleLazyLoadChange('display', entry);
                }
            });
            if (this.lazyload.fetch_threshold !== this.lazyload.display_threshold) {
                // set lazyload display observer
                var lazyload_display_options = {
                    root: null,
                    rootMargin: this.lazyload.display_threshold + '%',
                    threshold: 0.5
                };
                this.lazyloaddisplayObserver = new ViewabilityTracker_1.ViewabilityTracker(false, lazyload_display_options);
                this.lazyloaddisplayObserver.trackElement(this.element, function (entry) {
                    _this.handleLazyLoadChange('display', entry);
                });
            }
        }
    };
    Slot.prototype.getSetFloor = function () {
        this.floor = Floors_1.globalFloors.calculateSlotFloor(this);
        return this.floor;
    };
    Slot.prototype.removeLazyloadTrackers = function () {
        if (this.lazyloadFetchObserver) {
            // remove lazyload fetch observer
            this.lazyloadFetchObserver.untrackElement(this.element);
        }
        if (this.lazyloaddisplayObserver) {
            // remove lazyload display observer
            this.lazyloaddisplayObserver.untrackElement(this.element);
        }
    };
    Slot.prototype.setAutoDeleteTracker = function () {
        var _this = this;
        if (this.auto_delete.enabled) {
            // set lazyload fetch observer
            var auto_delete_options = {
                root: null,
                rootMargin: this.auto_delete.threshold + '%',
                threshold: 0.5
            };
            this.autoDeleteObserver = new ViewabilityTracker_1.ViewabilityTracker(false, auto_delete_options);
            this.autoDeleteObserver.trackElement(this.element, function (entry) {
                var boundingClientRect = entry.boundingClientRect, rootBounds = entry.rootBounds;
                // Check if the element is above the viewport
                if (boundingClientRect.bottom <= rootBounds.top) {
                    // console.log('Element is above the viewport.');
                    _this.removeSlot();
                }
            });
        }
    };
    Slot.prototype.removeAutoDeleteTracker = function () {
        if (this.autoDeleteObserver) {
            // remove lazyload fetch observer
            this.autoDeleteObserver.untrackElement(this.element);
        }
    };
    Slot.prototype.handleLazyLoadChange = function (threshold_type, entry) {
        var isIntersecting = entry.isIntersecting;
        if (threshold_type === 'fetch') {
            // Check if lazyload fetch has changed
            if (this.lazyload.in_fetch_range === isIntersecting)
                return;
            // set lazyload fetch range
            this.lazyload.in_fetch_range = isIntersecting;
            // fire custom event
            var event_1 = new CustomEvent('BcSlotLazyloadChange', { detail: { slot: this, threshold_type: threshold_type, isIntersecting: isIntersecting } });
            document.dispatchEvent(event_1);
            // check if slot needs a new auction
            if ((!this.auction &&
                !this.auction_finished &&
                this.refresh.count == 0) ||
                this.checkIfRefreshIsAllowed()) {
                this.sendNewAuction();
            }
        }
        else if (threshold_type === 'display') {
            if (this.lazyload.in_display_range === isIntersecting)
                return;
            // set lazyload display range
            this.lazyload.in_display_range = isIntersecting;
            // fire custom event
            var event_2 = new CustomEvent('BcSlotLazyloadChange', { detail: { slot: this, threshold_type: threshold_type, isIntersecting: isIntersecting } });
            document.dispatchEvent(event_2);
            // check if slot has winning bid
            if (this.checkIfShouldDisplayWinningBid()) {
                // display winning bid
                this.displayWinningBid();
            }
        }
    };
    Slot.prototype.sendNewAuction = function () {
        // check if an auction has already been sent
        if (!this.auction) {
            (0, log_1.logInfo)("sendNewAuction", this.slot_id, this.sizes);
            // set auction finished to false
            this.auction_finished = false;
            // add slot to auction
            this.auction = AuctionHandler_1.auctionHandler.addSlotsToAuction([this]);
            // send auction request. Allow 100 ms to queue up other slots
            AuctionHandler_1.auctionHandler.sendAuctionRequest(100);
        }
    };
    /**
     * Insert slot into element
     * @returns boolean
     */
    Slot.prototype.insertSlot = function () {
        var _this = this;
        try {
            if (this.sticky.enabled) {
                this.referenceElement.classList.add('bigcrunch-unit-injected');
                // outer sticky div
                var outer_div = document.createElement('div');
                outer_div.classList.add('bigcrunch-unit');
                outer_div.classList.add('bigcrunch-outer-sticky');
                outer_div.style.display = 'none';
                // close button div
                var close_div = document.createElement('div');
                close_div.classList.add('bigcrunch-close-sticky');
                close_div.innerHTML = "\n                    <svg class=\"x_close_logo\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 320 512\">\n                        <path fill=\"currentColor\" d=\"M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z\"></path>\n                    </svg>";
                close_div.addEventListener('click', (function () {
                    _this.removeSlot();
                }).bind(this));
                // append close div to outer div
                outer_div.appendChild(close_div);
                // insert div inside wrapper div
                var inner_div = document.createElement('div');
                inner_div.classList.add('bigcrunch-sticky-ad-container');
                // add id to div
                inner_div.id = this.div_id;
                // append inner div to outer div
                outer_div.appendChild(inner_div);
                // append to reference element
                this.referenceElement.appendChild(outer_div);
                // set wrapper element
                this.wrapperElement = outer_div;
                // set element
                this.element = inner_div;
            }
            else if (this.dynamic_inview['enabled']) {
                var dynamic_inview_div = document.createElement('div');
                dynamic_inview_div.id = "dynamic-overlay-".concat(this.slot_id);
                dynamic_inview_div.style.position = 'absolute';
                // dynamic_inview_div.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Example: semi-transparent black
                dynamic_inview_div.style.alignItems = 'center';
                dynamic_inview_div.style.justifyContent = 'center';
                dynamic_inview_div.style.zIndex = '2';
                // insert div inside wrapper div
                var inner_div = document.createElement('div');
                // add id to div
                inner_div.id = this.div_id;
                inner_div.classList.add('bigcrunch-unit');
                // append inner div to outer div
                dynamic_inview_div.appendChild(inner_div);
                // Append overlay to body
                document.body.appendChild(dynamic_inview_div);
                // set wrapper element
                this.wrapperElement = dynamic_inview_div;
                // set element
                this.element = inner_div;
                // Store the bound function in a variable
                this.updateOverlay = this.updateOverlay.bind(this);
                // Initial positioning of the overlay
                this.updateOverlay();
                // Update overlay on scroll and resize
                window.addEventListener('scroll', this.updateOverlay);
                window.addEventListener('resize', this.updateOverlay);
                this.bodyResizeObserver = new ResizeObserver(function () {
                    _this.updateOverlay();
                });
                this.bodyResizeObserver.observe(document.body);
                this.elemResizeObserver = new ResizeObserver((function () {
                    _this.resizeDynamicOverlay();
                }).bind(this));
                this.elemResizeObserver.observe(this.element);
            }
            else {
                this.referenceElement.classList.add('bigcrunch-unit-injected');
                // insert div inside wrapper div
                var inner_div = document.createElement('div');
                // add id to div
                inner_div.id = this.div_id;
                inner_div.classList.add('bigcrunch-unit');
                // TODO: Add min height for non stick display units
                // append to reference element
                this.referenceElement.appendChild(inner_div);
                // set wrapper element
                this.wrapperElement = inner_div;
                // set element
                this.element = inner_div;
            }
            return true;
        }
        catch (error) {
            (0, log_1.logError)(error);
            return false;
        }
    };
    Slot.prototype.resizeDynamicOverlay = function () {
        var rect = this.element.getBoundingClientRect();
        var freeze_css_class = "dynamic-overlay-freeze";
        if (rect.height > 1500 &&
            this.displayedBid.bidder === 'flipp' &&
            this.closestElement instanceof HTMLElement &&
            !this.closestElement.classList.contains(freeze_css_class)) {
            // disable slots refresh
            this.disableRefresh();
            // remove injected css class from all elements 
            var elements = this.searchPageForDynamicInviewElements();
            for (var i = 0; i < elements.length; i++) {
                if (elements[i] !== this.closestElement) {
                    elements[i].classList.remove('bigcrunch-unit-injected');
                }
            }
            // add freeze css class to closest element
            this.closestElement.classList.add(freeze_css_class);
            // gerneate unique css class
            this.dynamic_inview['css_class'] = 'bc-' + (0, utils_1.generateUUID)();
            // set css selector
            this.css_selector = ".".concat(this.dynamic_inview['css_class']);
            // add css class to closest element
            this.closestElement.classList.add(this.dynamic_inview['css_class']);
            // Update the overlay
            this.updateOverlay();
            // build new dynamic slot                        
            Page_1.globalPage.buildSlots((0, utils_1.deepCopy)(__1.globalSettings.slots) || {});
        }
        else {
            this.updateOverlay();
        }
    };
    Slot.prototype.searchPageForDynamicInviewElements = function () {
        var css_not = this.dynamic_inview['css_class'] ? '' : ':not(.dynamic-overlay-freeze)';
        var elements = document.querySelectorAll("".concat(this.css_selector).concat(css_not)) || [];
        if (elements.length) {
            return elements;
        }
        return [];
    };
    // Function to update the overlay size and position
    Slot.prototype.updateOverlay = function () {
        try {
            var closestElement = this.getClosestElement();
            if (closestElement) {
                // Check if the closest element has changed
                if (this.closestElement !== closestElement) {
                    // check if a bid is displayed
                    if (this.displayedBid instanceof Bid_1.Bid && this.displayedBid.displayed) {
                        if ((['unfilled', 'house_ads', 'unknown'].includes(this.displayedBid.bidder) &&
                            Date.now() - this.displayed_ts > 5000) ||
                            this.refresh.inview_interval_met) {
                            var remove = this.prebid.bid_caching ? ['googletag'] : ['prebid', 'googletag'];
                            this.removeDynamicInviewSlot(remove);
                            // build new dynamic slot
                            Page_1.globalPage.buildSlots((0, utils_1.deepCopy)(__1.globalSettings.slots) || {});
                            return;
                        }
                    }
                }
                var rect = closestElement.getBoundingClientRect();
                var width = Math.max(rect.width, closestElement.clientWidth);
                var height = Math.max(rect.height, closestElement.clientHeight);
                // Calculate the overlay's position relative to the document
                var top_1 = rect.top + window.scrollY;
                var left = rect.left + window.scrollX;
                if (this.dynamic_inview['sticky']) {
                    var parentElement = closestElement.parentElement;
                    var parentRect = parentElement.getBoundingClientRect();
                    // Get the computed style of the element
                    var parentStyle = window.getComputedStyle(parentElement);
                    var parentHeight = parseInt(parentStyle.height.replace('px', ''));
                    var padding = 25;
                    if (parentHeight > height + (padding * 2)) {
                        if (parentRect.bottom > 0 &&
                            parentRect.top + padding <= 0 &&
                            parentRect.bottom - padding >= height) {
                            this.element.style.position = 'fixed';
                            this.element.style.top = '0px';
                            top_1 = window.scrollY;
                        }
                        else {
                            if (parentRect.bottom > 0 &&
                                parentRect.top + padding <= 0 &&
                                parentRect.bottom - padding < height) {
                                top_1 = Math.round(window.scrollY - height + parentRect.bottom - padding);
                            }
                            this.element.style.position = '';
                            this.element.style.top = '';
                        }
                    }
                    else {
                        this.element.style.position = '';
                        this.element.style.top = '';
                    }
                }
                this.closestElement = closestElement;
                // Update overlay styles
                this.wrapperElement.style.width = width + 'px';
                this.wrapperElement.style.height = height + 'px';
                this.wrapperElement.style.top = top_1 + 'px';
                this.wrapperElement.style.left = left + 'px';
                this.wrapperElement.style.display = 'flex'; // Show the overlay
            }
            else {
                // this.wrapperElement.style.display = 'none'; // Hide the overlay if no element is found
                this.removeDynamicInviewSlot();
            }
        }
        catch (error) {
            (0, log_1.logError)(error);
        }
    };
    Slot.prototype.getClosestElement = function () {
        var elements = this.searchPageForDynamicInviewElements();
        var closestElement = null;
        var closestDistance = Infinity;
        var viewportMidpoint = (window.innerHeight / 2) + window.scrollY;
        // Loop through all elements with the specified class
        Array.from(elements).forEach(function (element) {
            // Get the element's bounding rectangle
            var rect = element.getBoundingClientRect();
            var width = Math.max(rect.width, element.clientWidth);
            var height = Math.max(rect.height, element.clientHeight);
            // check if width or height is 0
            if (!width || !height) {
                // get height of parent element
                var parentElement = element.parentElement;
                var parentRect = parentElement.getBoundingClientRect();
                width = Math.max(parentRect.width, parentElement.clientWidth, this.min_width);
                height = Math.max(parentRect.height, parentElement.clientHeight, this.min_height);
                // set width and height of element
                element.style.width = "".concat(width, "px");
                element.style.height = "".concat(height, "px");
                // Get the element's bounding rectangle
                rect = element.getBoundingClientRect();
            }
            // check if element has been injected
            if (!element.classList.contains('bigcrunch-unit-injected')) {
                // add class to element
                element.classList.add('bigcrunch-unit-injected');
                if (this.dynamic_inview['sticky']) {
                    element.style.position = 'sticky';
                    element.style.top = '0px';
                }
                element.style.minWidth = "".concat(this.min_width, "px");
                element.style.minHeight = "".concat(this.min_height, "px");
            }
            // if a bid is displayed
            if (this.displayedBid instanceof Bid_1.Bid && this.displayedBid.displayed) {
                width = Math.max(this.displayedBid.data.w, this.element.clientWidth);
                height = Math.max(this.displayedBid.data.h, this.element.clientHeight);
                element.style.width = "".concat(width, "px");
                element.style.height = "".concat(height, "px");
                // Get the element's bounding rectangle
                rect = element.getBoundingClientRect();
            }
            var elementMidpoint = rect.top + (height / 2) + window.scrollY;
            // Calculate the distance from the middle of the element to the midpoint of the viewport
            var distanceToMidpoint = Math.abs(elementMidpoint - viewportMidpoint);
            // Find the element closest to the bottom of the viewport
            if (distanceToMidpoint < closestDistance &&
                !(rect.top === 0 &&
                    rect.bottom === 0 &&
                    rect.width === 0 &&
                    rect.height === 0)) {
                closestElement = element;
                closestDistance = distanceToMidpoint;
            }
        }.bind(this));
        return closestElement;
    };
    Slot.prototype.removeDynamicInviewSlot = function (remove) {
        if (remove === void 0) { remove = ['prebid', 'googletag']; }
        // remove event listeners
        window.removeEventListener('scroll', this.updateOverlay);
        window.removeEventListener('resize', this.updateOverlay);
        this.bodyResizeObserver.disconnect();
        this.elemResizeObserver.disconnect();
        // remove injected css class from all elements
        var elements = this.searchPageForDynamicInviewElements();
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove('bigcrunch-unit-injected');
        }
        // remove slot from page
        this.removeSlot(remove);
    };
    /**
     * @returns Array<string>
     * @description Set sizes for slot
     */
    Slot.prototype.setSizes = function () {
        try {
            var mediaTypeSizes_1 = this.config['mediaTypes'] || {};
            var sizes_1 = {};
            var page_width_1 = (0, utils_1.getPageWidth)();
            // loop through resolution sizes
            Object.keys(mediaTypeSizes_1).forEach(function (mediaType) {
                var current_resolution = 0;
                var mediaTypeSizeConfig = mediaTypeSizes_1[mediaType].sizeConfig || [];
                for (var _i = 0, mediaTypeSizeConfig_1 = mediaTypeSizeConfig; _i < mediaTypeSizeConfig_1.length; _i++) {
                    var sizeConfig = mediaTypeSizeConfig_1[_i];
                    var resolution = parseInt(sizeConfig.minViewPort[0]);
                    if (resolution <= page_width_1 &&
                        resolution >= current_resolution) {
                        current_resolution = resolution;
                        sizes_1[mediaType] = sizeConfig.sizes;
                    }
                }
            });
            return sizes_1;
        }
        catch (error) {
            (0, log_1.logWarn)("Unable to set sizes for slot.", this.slot_id);
            return [];
        }
    };
    Slot.prototype.getMinHeight = function () {
        var _this = this;
        if (Object.keys(this.sizes).length) {
            Object.keys(this.sizes).forEach(function (mediaType) {
                // loop through sizes
                for (var _i = 0, _a = _this.sizes[mediaType]; _i < _a.length; _i++) {
                    var size = _a[_i];
                    // get height
                    var height = parseInt(size[1]);
                    // check if height is greater than min_height
                    if ((height < _this.min_height || _this.min_height == 0) && height > 1) {
                        _this.min_height = height;
                    }
                    // check if width is greater than min_width
                    var width = parseInt(size[0]);
                    if ((width > _this.min_width || _this.min_height == 0) && width > 1) {
                        _this.min_width = width;
                    }
                }
            });
        }
    };
    Slot.prototype.checkIfShouldDisplayWinningBid = function () {
        var isFirstImpression = !this.ad_injected && !this.displayed && this.refresh.count === 0;
        var isRefreshImpression = this.refresh.force || (this.refresh.enabled &&
            this.refresh.interval_met &&
            this.refresh.inview_interval_met &&
            this.refresh.count > 0);
        var isLazyLoadMet = !this.lazyload.enabled || this.lazyload.in_display_range;
        var isAuctionFinished = !this.auction && this.auction_finished;
        var isTabActive = Page_1.globalPage.isTabActive;
        var isPrebidMet = !this.prebid.enabled || !__1.globalSettings.prebid.enabled ||
            (!this.adserver.auction_started &&
                this.prebid.header_auction_finished);
        var isAmazonAPSMet = !this.amazon_aps.enabled || !__1.globalSettings.amazon_aps.enabled ||
            (!this.adserver.auction_started &&
                this.amazon_aps.auction_finished);
        return ((isFirstImpression || isRefreshImpression) &&
            isLazyLoadMet &&
            isAuctionFinished &&
            isTabActive &&
            isPrebidMet &&
            isAmazonAPSMet);
    };
    Slot.prototype.auctionFinished = function (bids) {
        var _this = this;
        // increment no bid count if no bids are returned
        this.auction_no_bid_count = (bids.length === 0) ? this.auction_no_bid_count + 1 : 0;
        // add bids to slot
        this.addBids(bids);
        // set auction finished to true
        this.auction = null;
        this.auction_finished = true;
        this.auction_interval_met = false;
        // Set timeout until next auction is allowed
        setTimeout(function () {
            _this.auction_interval_met = true;
            _this.checkIfRefreshIsAllowed();
        }, this.auction_min_interval || 5000);
        // check if slot has winning bid
        if (this.checkIfShouldDisplayWinningBid()) {
            // display winning bid
            this.displayWinningBid();
        }
    };
    /**
     * @param {Array<Bid>} bids
     * @returns void
     * @description Add bids to slot
     */
    Slot.prototype.addBids = function (bids) {
        var _a;
        // add bids to slot
        (_a = this.bids).push.apply(_a, bids);
        // sort bids by price from highest to lowest
        this.bids.sort(function (a, b) {
            return b.data.price - a.data.price;
        });
        // remove expired bids
        if (this.bids.length) {
            // remove expired bids
            this.removeExpiredBids();
        }
    };
    /**
     * @returns boolean
     */
    Slot.prototype.getWinningBid = function () {
        if (this.hasWinningBid()) {
            this.addBids([this.winningBid]);
        }
        if (this.bids.length) {
            var min_bid_to_win = (this.getNextHighestBid() || this.floor) + 0.01;
            this.winningBid = this.bids.shift() || null;
            if (min_bid_to_win) {
                this.winningBid.min_bid_to_win = min_bid_to_win;
            }
        }
        return this.hasWinningBid();
    };
    /**
     * @returns number
     * @description Get minimum bid to win
     */
    Slot.prototype.getNextHighestBid = function () {
        if (this.bids.length > 1) {
            return this.bids[1].data.price;
        }
        return 0;
    };
    /**
     * @returns boolean
     */
    Slot.prototype.hasWinningBid = function () {
        return this.winningBid instanceof Bid_1.Bid;
    };
    /**
     * @returns boolean
     * @description Display winning bid
     */
    Slot.prototype.displayWinningBid = function () {
        // check if slot has winning bid
        if (this.getWinningBid()) {
            // check if lazyload is enabled and slot is not in display range
            if (this.lazyload.enabled &&
                this.lazyload.in_display_range === false)
                return false;
            // set displayed data
            this.ad_injected = true;
            // Send provisional win notice
            this.winningBid.sendNurlPixel();
            // check if winning bid is a video
            if (this.winningBid.demand_type === 'video') {
                this.insertOutstreamPlayer();
                // check if winning bid is a display
            }
            else if (this.winningBid.demand_type === 'banner') {
                // Remove an existing video ad
                if (this.videoPlayer) {
                    this.videoPlayer.deleteVideoPlayerFromDom(function () {
                        // delete video player
                        delete this.videoPlayer;
                        this.videoPlayer = null;
                        // insert display ad
                        this.injectAdCode(this.winningBid.data);
                    }.bind(this));
                }
                else {
                    // insert display ad
                    this.injectAdCode(this.winningBid.data);
                }
            }
        }
        else {
            if (!this.displayed &&
                !this.ad_injected &&
                this.refresh.count == 0) {
                // fire custom event
                var event_3 = new CustomEvent('BcFirstImpressionUnfilled', { detail: this });
                document.dispatchEvent(event_3);
            }
            else {
                // fire custom event
                var event_4 = new CustomEvent('BcRefreshImpressionUnfilled', { detail: this });
                document.dispatchEvent(event_4);
                if (this.videoPlayer) {
                    this.videoPlayer.deleteVideoPlayerFromDom(function () {
                        // delete video player
                        delete this.videoPlayer;
                        this.videoPlayer = null;
                    }.bind(this));
                }
            }
        }
        return true;
    };
    // function to put ad code into an iframe and display it in the slots element with the div_id
    // @param {string} adCode
    // @returns {boolean}
    Slot.prototype.injectAdCode = function (ad) {
        try {
            // Insert Display Ad
            // create iframe
            var iframe = document.createElement('iframe');
            // set iframe id
            iframe.id = this.div_id;
            // set iframe srcdoc
            iframe.srcdoc = ad.adm;
            // set iframe style
            iframe.style.width = ad.w;
            iframe.style.height = ad.h;
            // insert iframe into element
            this.element.appendChild(iframe);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    /**
     * @returns void
     * @description Reset refresh
     */
    Slot.prototype.resetRefresh = function () {
        // Reset Flags
        this.refresh.interval_met = false;
        this.refresh.inview_interval_met = false;
        // Clear Timeouts
        clearTimeout(this.refresh.interval_timer);
        this.refresh.interval_timer = null;
        clearTimeout(this.refresh.inview_interval_timer);
        this.refresh.inview_interval_timer = null;
        // Reset Intervals
        this.refresh.current_interval = this.refresh.interval;
        this.refresh.current_inview_interval = this.refresh.inview_interval;
    };
    Slot.prototype.disableRefresh = function () {
        this.refresh.enabled = false;
        this.resetRefresh();
    };
    Slot.prototype.resetViewability = function () {
        // Reset Viewability Data
        this.viewability.time_inview_since_displayed = 0;
        var isInView = this.viewability.inview;
        this.viewability.inview = null;
        this.handleViewabilityChange(isInView);
    };
    /**
     * @returns boolean
     * @description Check if refresh is allowed
     */
    Slot.prototype.checkIfRefreshIsAllowed = function () {
        if (!this.auction &&
            this.displayed &&
            this.refresh.enabled &&
            this.refresh.interval_met &&
            this.refresh.inview_interval_met &&
            this.refresh.count < this.refresh.max &&
            this.refresh.unfilled_count < this.refresh.unfilled_max &&
            // this.auction_no_bid_count < 5 &&
            this.auction_interval_met &&
            Page_1.globalPage.isTabActive &&
            (!this.lazyload.enabled ||
                this.lazyload.in_fetch_range)) {
            return true;
        }
        return false;
    };
    // function to set displayed flag
    Slot.prototype.setDisplayed = function () {
        // set displayed flag
        this.displayed = true;
        this.displayed_ts = Date.now();
        // reset refresh
        this.resetRefresh();
        // reset viewability
        this.resetViewability();
        // set refresh timer
        this.setRefreshTimer();
        // If slot is inview
        if (this.viewability.inview ||
            this.sticky.enabled) {
            // Set entered inview timestamp
            this.viewability.entered_inview_ts = Date.now();
            // set inview refresh timer if allowed
            this.setInviewRefreshTimer();
            // set viewability timer
            this.displayedBid.setViewabilityTimer();
        }
        // reset force refresh flag
        if (this.refresh.force) {
            this.refresh.force = false;
        }
        if (this.dynamic_inview['enabled']) {
            this.updateOverlay();
        }
        // fire custom evert
        var event = new CustomEvent('BcSlotDisplayed', { detail: this });
        document.dispatchEvent(event);
    };
    /**
     * @returns boolean
     * @description Refresh slot
     */
    Slot.prototype.refreshSlot = function (force) {
        if (force === void 0) { force = false; }
        if (this.checkIfRefreshIsAllowed() || force) {
            if (force) {
                this.refresh.force = true;
                this.resetRefresh();
            }
            // fire custom event
            var event_5 = new CustomEvent('BcSlotRefresh', { detail: this });
            document.dispatchEvent(event_5);
            // send new auction
            this.sendNewAuction();
            // increment refresh count
            this.refresh.count++;
        }
        return true;
    };
    /**
     * @returns boolean
     * @description Refresh slot
     */
    Slot.prototype.setRefreshTimer = function () {
        var _this = this;
        // check if refresh is enabled
        if (this.refresh.interval_met ||
            !this.refresh.enabled ||
            !this.displayed) {
            return false;
        }
        // reset refresh
        this.resetRefresh();
        // Get time since slot was displayed
        var time_since_displayed = Date.now() - this.displayed_ts;
        // Set current interval
        this.refresh.current_interval = Math.max(this.refresh.interval - time_since_displayed, 0);
        if (this.refresh.current_interval > 0) {
            (0, log_1.logInfo)("Refresh interval for ".concat(this.slot_id, ": ").concat(this.refresh.current_interval));
            // set refresh timer
            this.refresh.interval_timer = setTimeout(function () {
                _this.setRefreshIntervalMet();
            }, this.refresh.current_interval);
        }
        else {
            this.setRefreshIntervalMet();
        }
        return true;
    };
    Slot.prototype.clearRefreshTimer = function () {
        if (this.refresh.interval_timer !== null) {
            clearTimeout(this.refresh.interval_timer);
            this.refresh.interval_timer = null;
        }
    };
    Slot.prototype.setRefreshIntervalMet = function () {
        this.refresh.interval_met = true;
        if (this.checkIfRefreshIsAllowed()) {
            // refresh slot
            this.refreshSlot();
        }
    };
    Slot.prototype.setInviewRefreshTimer = function () {
        var _this = this;
        if (!this.displayed ||
            !this.refresh.enabled) {
            return true;
        }
        // check if inview refresh is enabled
        if (this.refresh.inview_interval == -1 ||
            this.refresh.inview_interval_met) {
            this.setInviewIntervalMet();
        }
        else if (this.viewability.inview || this.sticky.enabled) {
            // Clear inview refresh timer if it exists
            if (this.refresh.inview_interval_timer !== null) {
                clearTimeout(this.refresh.inview_interval_timer);
                this.refresh.inview_interval_timer = null;
            }
            // Set current inview interval to inview interval if it is not set
            this.refresh.current_inview_interval = this.refresh.current_inview_interval || this.refresh.inview_interval;
            // Set current interval
            this.refresh.current_inview_interval = Math.max(this.refresh.current_inview_interval - this.viewability.time_inview_since_displayed, 0);
            if (this.refresh.current_inview_interval > 0) {
                // set invew refresh timer
                this.refresh.inview_interval_timer = setTimeout(function () {
                    _this.setInviewIntervalMet();
                }, this.refresh.current_inview_interval);
            }
            else {
                this.setInviewIntervalMet();
            }
        }
        return true;
    };
    Slot.prototype.setInviewIntervalMet = function () {
        this.refresh.inview_interval_met = true;
        if (this.checkIfRefreshIsAllowed()) {
            // refresh slot
            this.refreshSlot();
        }
    };
    Slot.prototype.handleViewabilityChange = function (isInView) {
        var _a, _b, _c;
        // If sticky is enabled, set inview to true
        if (this.sticky.enabled && Page_1.globalPage.isTabActive)
            isInView = true;
        // Check if viewability has changed
        if (this.viewability.inview === isInView)
            return;
        // Set inView
        this.viewability.inview = isInView;
        // Perform actions when the element is in view
        (0, log_1.logInfo)("Element ".concat(this.slot_id, ",  inview: ").concat(isInView));
        // Check if the element is in view
        if (isInView) {
            // Check if slot is displayed and bid is displayed
            if (this.displayed && ((_a = this.displayedBid) === null || _a === void 0 ? void 0 : _a.displayed)) {
                // Set entered inview timestamp
                this.viewability.entered_inview_ts = Date.now();
                // set inview refresh timer if allowed
                this.setInviewRefreshTimer();
                // set viewability timer
                this.displayedBid.setViewabilityTimer();
            }
            // check if video player is paused
            if (this.videoPlayer &&
                (!this.videoPlayer.ad_started ||
                    this.videoPlayer.ad_paused)) {
                // play ads
                this.videoPlayer.playAds();
            }
        }
        else { // Element is out of view
            // Check if slot is displayed and bid is displayed
            if (this.displayed && ((_b = this.displayedBid) === null || _b === void 0 ? void 0 : _b.displayed)) {
                if (this.refresh.inview_interval !== null) {
                    clearTimeout(this.refresh.inview_interval_timer);
                    this.refresh.inview_interval_timer = null;
                }
                // set time since slot was displayed
                this.setTimeInviewSinceDisplayed();
                // clear viewability timer
                (_c = this.displayedBid) === null || _c === void 0 ? void 0 : _c.clearViewabilityTimer();
            }
            // check if video player is paused
            if (this.videoPlayer &&
                this.videoPlayer.ad_started &&
                !this.videoPlayer.ad_paused) {
                // pause ads
                this.videoPlayer.pauseAds();
            }
        }
    };
    Slot.prototype.handleActiveTabChange = function (isTabActive) {
        // Check if tab is active
        if (isTabActive) {
            if (this.refresh.interval_met) {
                this.checkIfRefreshIsAllowed();
            }
            else {
                this.setRefreshTimer();
            }
        }
        else {
            this.clearRefreshTimer();
        }
    };
    /**
     * @returns void
     * @description Set time since slot was displayed
     */
    Slot.prototype.setTimeInviewSinceDisplayed = function () {
        // Get time since slot was displayed
        this.viewability.time_inview_since_displayed = this.viewability.time_inview_since_displayed || 0;
        if (this.viewability.entered_inview_ts) {
            var entered_inview_ts = Math.max(this.viewability.entered_inview_ts, this.displayed_ts);
            this.viewability.time_inview_since_displayed += Math.max(Date.now() - entered_inview_ts, 0);
        }
    };
    /**
     * Insert video div into element
     * @returns boolean
     */
    Slot.prototype.insertOutstreamPlayer = function () {
        var _this = this;
        try {
            if (!this.videoPlayer) {
                // Create video instance
                this.videoPlayer = new VideoInstance_1.VideoInstance(this.div_id, this, function (success, deleteSlot) {
                    if (deleteSlot) {
                        delete _this.videoPlayer;
                        _this.videoPlayer = null;
                        _this.removeSlot();
                    }
                    else if (success) {
                        // Refresh slot
                        _this.refreshSlot();
                    }
                    else if (!success) {
                        // Reset flags
                        _this.displayed = false;
                        _this.ad_injected = false;
                        // Display winning bid
                        _this.displayWinningBid();
                    }
                });
            }
            else {
                this.videoPlayer.initializeIma();
            }
            return true;
        }
        catch (error) {
            console.error("Unable to insert outstream player into slot.", this.slot_id, error);
            return false;
        }
    };
    Slot.prototype.removeNativeStickyAd = function () {
        if (this.refresh.count > 0) {
            try {
                if (this.displayedBid.bidder === 'gumgum') {
                    if (globalThis.GUMGUM && globalThis.GUMGUM.isad) {
                        globalThis.GUMGUM.removeISAd();
                    }
                }
                else if (this.displayedBid.bidder === 'kargo') {
                    if (globalThis.Kargo) {
                        globalThis.Kargo.CreativeRegister.getCreativesOfType('Hover')[0].destroy();
                    }
                }
                else if (this.displayedBid.bidder === 'ogury') {
                    if (document.getElementById("ogy-ad-slot")) {
                        window.top.dispatchEvent(new Event('ogy_hide'));
                        if (document.getElementById("ogy-ad-slot")) {
                            document.getElementById("ogy-ad-slot").remove();
                        }
                    }
                }
            }
            catch (error) {
                console.error(error);
                return false;
            }
        }
        return true;
    };
    /**
     * @returns void
     * @description Remove expired bids
     */
    Slot.prototype.removeExpiredBids = function () {
        var i = 0;
        while (i < this.bids.length) {
            // Check if the element is expired
            if (this.bids[i].isExpired()) {
                // Remove the element from the array
                this.bids.splice(i, 1);
            }
            else {
                // Increment the index only if no element is removed
                i++;
            }
        }
    };
    /**
     * @returns void
     * @description Remove slot from page
    */
    Slot.prototype.removeSlot = function (remove) {
        if (remove === void 0) { remove = ['prebid', 'googletag']; }
        try {
            // remove slot from page
            this.wrapperElement.remove();
            // disable refresh
            this.disableRefresh();
            // remove viewability tracker
            this.removeViewabilityTracker();
            // remove lazyload trackers
            this.removeLazyloadTrackers();
            // remove lazyload trackers
            this.removeAutoDeleteTracker();
            // fire custom event that slot has been removed
            var event_6 = new CustomEvent('BcSlotRemoved', { detail: { "slot": this, "remove": remove } });
            document.dispatchEvent(event_6);
            // remove slot from page
            delete Page_1.globalPage.slots[this.slot_id];
        }
        catch (error) {
            (0, log_1.logError)(error);
        }
    };
    return Slot;
}());
exports.default = Slot;
