"use strict";
/**
 * @typedef {function} CMPClient
 *
 * @param {{}} params CMP parameters. Currently this is a subset of {command, callback, parameter, version}.
 * @param {bool} once if true, discard cross-frame event listeners once a reply message is received.
 * @returns {Promise<*>} a promise to the API's "result" - see the `mode` argument to `cmpClient` on how that's determined.
 * @property {boolean} isDirect true if the CMP is directly accessible (no postMessage required)
 * @property {() => void} close close the client; currently, this just stops listening for cross-frame messages.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODE_CALLBACK = exports.MODE_RETURN = exports.MODE_MIXED = void 0;
exports.cmpClient = cmpClient;
/**
 * Returns a client function that can interface with a CMP regardless of where it's located.
 *
 * @param apiName name of the CMP api, e.g. "__gpp"
 * @param apiVersion? CMP API version
 * @param apiArgs? names of the arguments taken by the api function, in order.
 * @param mode? controls the callbacks passed to the underlying API, and how the promises returned by the client are resolved.
 *
 *  The client behaves differently when it's provided a `callback` argument vs when it's not - for short, let's name these
 *  cases "subscriptions" and "one-shot calls" respectively:
 *
 *  With `mode: MODE_MIXED` (the default), promises returned on subscriptions are resolved to undefined when the callback
 *  is first run (that is, the promise resolves when the CMP replies, but what it replies with is discarded and
 *  left for the callback to deal with). For one-shot calls, the returned promise is resolved to the API's
 *  return value when it's directly accessible, or with the result from the first (and, presumably, the only)
 *  cross-frame reply when it's not;
 *
 *  With `mode: MODE_RETURN`, the returned promise always resolves to the API's return value - which is taken to be undefined
 *  when cross-frame;
 *
 *  With `mode: MODE_CALLBACK`, the underlying API is expected to never directly return anything significant; instead,
 *  it should always accept a callback and - for one-shot calls - invoke it only once with the result. The client will
 *  automatically generate an appropriate callback for one-shot calls and use the result it's given to resolve
 *  the returned promise. Subscriptions are treated in the same way as MODE_MIXED.
 *
 * @param win
 * @returns {CMPClient} CMP invocation function (or null if no CMP was found).
 */
exports.MODE_MIXED = 0;
exports.MODE_RETURN = 1;
exports.MODE_CALLBACK = 2;
function cmpClient(_a, win) {
    var _b = _a.apiName, apiName = _b === void 0 ? '' : _b, _c = _a.apiVersion, apiVersion = _c === void 0 ? 0 : _c, _d = _a.apiArgs, apiArgs = _d === void 0 ? ['command', 'callback', 'parameter', 'version'] : _d, _e = _a.mode, mode = _e === void 0 ? exports.MODE_MIXED : _e;
    if (win === void 0) { win = window; }
    function findCMP() {
        var cmpFrame = null;
        var isDirect = false;
        if (typeof win[apiName] === 'function') {
            cmpFrame = win;
            isDirect = true;
        }
        return [
            cmpFrame,
            isDirect
        ];
    }
    var _f = findCMP(), cmpFrame = _f[0], isDirect = _f[1];
    if (!cmpFrame) {
        return;
    }
    function resolveParams(params) {
        params = Object.assign({ version: apiVersion }, params);
        return apiArgs.map(function (arg) { return [arg, params[arg]]; });
    }
    // wrap the callback to resolve the promise
    function wrapCallback(callback, resolve, reject) {
        // if the CMP returns a value, resolve the promise with it
        var haveCb = typeof callback === 'function';
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var result = args[0], success = args[1];
            if (mode !== exports.MODE_RETURN) {
                var resolver = success == null || success ? resolve : reject;
                resolver(haveCb ? undefined : result);
            }
            haveCb && callback.apply(this, args);
        };
    }
    var client;
    if (isDirect) {
        client = function invokeCMPDirect(params) {
            return new Promise(function (resolve, reject) {
                var ret = cmpFrame[apiName].apply(cmpFrame, resolveParams(__assign(__assign({}, params), { callback: (params.callback || mode === exports.MODE_CALLBACK) ? wrapCallback(params.callback, resolve, reject) : undefined })).map(function (_a) {
                    var val = _a[1];
                    return val;
                }));
                if (mode === exports.MODE_RETURN || (params.callback == null && mode === exports.MODE_MIXED)) {
                    resolve(ret);
                }
            });
        };
    }
    else {
        return;
    }
    return Object.assign(client, {
        isDirect: isDirect
    });
}
