"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalFloors = exports.Floors = void 0;
var __1 = require("../");
var Session_1 = require("../classes/Session");
var utils_1 = require("../utils/utils");
var envVars_1 = require("../envVars");
var ua_parser_js_1 = require("ua-parser-js");
var Page_1 = require("./Page");
var Floors = /** @class */ (function () {
    function Floors() {
        this.dynamicFloors = {
            "slots": {}, // Slot Floors
            "refreshLR": [], // Refresh Linear Regression
            "sessionLR": [] // Session Linear Regression
        };
    }
    Floors.prototype.getDynamicFloors = function () {
        var _this = this;
        var ua = new ua_parser_js_1.UAParser((0, utils_1.getUserAgent)());
        var device = (0, utils_1.categorizeDeviceType)(ua.getDevice().type || 'desktop');
        var browser = (0, utils_1.categorizeBrowser)(ua.getBrowser().name);
        var country = (0, utils_1.categorizeCountryWithMap)(__1.globalSettings.consent_country_code);
        var property_id = __1.globalSettings.property_id;
        // fetch flooring data from server
        fetch("https://".concat(envVars_1.endpoint_prefix, "ship.bigcrunch.com/flooring/").concat(property_id, "-").concat(device, "-").concat(browser, "-").concat(country, ".json?rev=").concat(__1.VERSION)).then(function (response) {
            if (response.ok) {
                return response.json();
            }
        }).then(function (data) {
            if (data && data.slots) {
                _this.dynamicFloors = data;
                // loop through globalPage slots and set the floors
                for (var slotName in Page_1.globalPage.slots) {
                    var slot = Page_1.globalPage.slots[slotName];
                    if (data.slots[slot.name]) {
                        // set the floor for the slot
                        slot.floor = _this.calculateSlotFloor(slot);
                    }
                }
                // fire custom event
                var event_1 = new CustomEvent('BcDynamicFloors', { detail: _this.dynamicFloors });
                document.dispatchEvent(event_1);
            }
        });
    };
    Floors.prototype.calculateSlotFloor = function (slot) {
        try {
            // Check if the slot name exists in the dynamic floors
            if (__1.globalSettings.floor.dynamic && this.dynamicFloors.slots[slot.name]) {
                var slotFloor = this.dynamicFloors.slots[slot.name];
                var sessionLR = this.dynamicFloors.sessionLR; // Session Linear Regression
                var refreshLR = this.dynamicFloors.refreshLR; // Refresh Linear Regression
                if ((0, utils_1.isNumber)(sessionLR[0]) && (0, utils_1.isNumber)(refreshLR[0])) {
                    var sessionLRValue = (sessionLR[0] * Session_1.globalSession.data.depth) + slotFloor;
                    var refreshLRValue = (refreshLR[0] * slot.refresh.count) + sessionLRValue;
                    return Math.round(Math.max(refreshLRValue, slot.default_floor) * 100) / 100;
                }
            }
        }
        catch (error) {
            console.error('Error calculating slot floor:', error);
        }
        return Math.round(Math.max(slot.floor, slot.default_floor) * 100) / 100; // Default floor value if slot name not found
    };
    return Floors;
}());
exports.Floors = Floors;
exports.globalFloors = new Floors();
