"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalFiveByFive = exports.FiveByFive = void 0;
var ConsentHandler_1 = require("./consent/ConsentHandler");
var activities_1 = require("../modules/consent/activities");
var moduleTypes_1 = require("../modules/consent/moduleTypes");
var utils_1 = require("../utils/utils");
var envVars_1 = require("../envVars");
var User_1 = require("./User");
var Storage_1 = __importStar(require("./Storage"));
var moduleTypes_2 = require("../modules/consent/moduleTypes");
var activities_2 = require("../modules/consent/activities");
var log_1 = require("../utils/log");
// Get the second week of the next month
var date = new Date();
var secondWeek = new Date(date.getFullYear(), date.getMonth(), 14);
var expires = secondWeek.toUTCString();
// calculate maxAge from expires
var maxAge = Math.floor((Date.parse(expires) - Date.now()) / 1000);
var HEM_STORAGE_NAME = "".concat(Storage_1.STORAGE_PREFIX, "fbf_hem");
var HEM_STORAGE_OPTIONS = {
    'maxAge': maxAge, // expire first week of next month
    'expires': expires, // expire first week of next month
    'path': '/', // root path
    'domain': '.' + (0, utils_1.getDomain)() || ((_a = window.location) === null || _a === void 0 ? void 0 : _a.hostname), // root domain
    'sameSite': 'None', // Allow cross-site cookies
    'secure': 1 // cookie will only be sent over SSL
};
var FiveByFive = /** @class */ (function () {
    function FiveByFive() {
        this.partnerID = '';
        this.hashedEmail = {
            'ip': '',
            'hem': ''
        };
    }
    FiveByFive.prototype.insertCookieSyncTag = function (partnerID) {
        this.partnerID = partnerID;
        if (this.partnerID && ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_1.ACTIVITY_SYNC_USER, { 'gvlid': null })) {
            // Create a new script element
            var script = document.createElement('script');
            // Set the source of the script to the URL of the cookie sync tag, inserting the partner ID and user ID
            script.src = 'https://a.usbrowserspeed.com/cs?pid=' + encodeURIComponent(this.partnerID);
            // Set the type of the script
            script.type = 'text/javascript';
            // Append the script to the body of the document
            document.body.appendChild(script);
        }
    };
    // Function to get users Hashed Email from storage or send a request to the server to get it
    FiveByFive.prototype.getUserHashedEmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hashedEmail;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        hashedEmail = (0, utils_1.safeJsonParse)(Storage_1.default.getItemFromStorage(HEM_STORAGE_NAME, { 'componentType': moduleTypes_1.MODULE_TYPE_CORE, 'gvlid': null }));
                        if (!(!hashedEmail ||
                            !hashedEmail.hem ||
                            (hashedEmail.ip &&
                                [User_1.globalUser.ipv4, User_1.globalUser.ipv6].indexOf(hashedEmail.ip) == -1))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.requestHashedEmail()];
                    case 1:
                        // Send a request to the server to get the hashed email
                        hashedEmail = _b.sent();
                        // If the hashed email was returned from the server
                        if (hashedEmail.hem) {
                            // Save the hashed email to storage
                            Storage_1.default.setItemInStorage(HEM_STORAGE_NAME, JSON.stringify(hashedEmail), { 'componentType': moduleTypes_1.MODULE_TYPE_CORE, 'gvlid': null }, HEM_STORAGE_OPTIONS);
                        }
                        _b.label = 2;
                    case 2:
                        this.hashedEmail = (hashedEmail && hashedEmail.hem !== 'N/A') ? hashedEmail : { 'ip': User_1.globalUser.ipv4 || User_1.globalUser.ipv6, 'hem': '' };
                        (0, log_1.logInfo)('5x5: HEM', this.hashedEmail);
                        if ((_a = this.hashedEmail) === null || _a === void 0 ? void 0 : _a.hem) {
                            User_1.globalUser.setHashedEmail('5x5', { 'SHA256': this.hashedEmail.hem }, '3p');
                        }
                        return [2 /*return*/, this.hashedEmail];
                }
            });
        });
    };
    // Function to send a request to the server to get the users hashed email
    FiveByFive.prototype.requestHashedEmail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (0, utils_1.fetchWithTimeout)("https://".concat(envVars_1.endpoint_prefix, "data.bigcrunch.com/user/hem/v2"), 2000, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                consent: (_a = {},
                                    _a[activities_2.ACTIVITY_ACCESS_DEVICE] = ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_2.ACTIVITY_ACCESS_DEVICE, { 'componentType': moduleTypes_2.MODULE_TYPE_UID, 'gvlid': null }),
                                    _a[activities_2.ACTIVITY_TRANSMIT_EIDS] = ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_2.ACTIVITY_TRANSMIT_EIDS, { 'componentType': moduleTypes_2.MODULE_TYPE_UID, 'gvlid': null }),
                                    _a[activities_2.ACTIVITY_TRANSMIT_PRECISE_GEO] = ConsentHandler_1.allConsentHandler.isActivityAllowed(activities_2.ACTIVITY_TRANSMIT_PRECISE_GEO, { 'componentType': moduleTypes_2.MODULE_TYPE_UID, 'gvlid': null }),
                                    _a)
                            })
                        })];
                    case 1:
                        response = _b.sent();
                        // If the request was successful
                        if (response.ok) {
                            // Return the hashed email
                            return [2 /*return*/, response.json()];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    return FiveByFive;
}());
exports.FiveByFive = FiveByFive;
exports.globalFiveByFive = new FiveByFive();
