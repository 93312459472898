"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalTrafficShaping = exports.TrafficShaping = void 0;
var __1 = require("../");
var utils_1 = require("../utils/utils");
var envVars_1 = require("../envVars");
var ua_parser_js_1 = require("ua-parser-js");
var TrafficShaping = /** @class */ (function () {
    function TrafficShaping() {
        this.ready = false;
    }
    TrafficShaping.prototype.getBidderScores = function () {
        var _this = this;
        var ua = new ua_parser_js_1.UAParser((0, utils_1.getUserAgent)());
        var device = (0, utils_1.categorizeDeviceType)(ua.getDevice().type || 'desktop');
        var browser = (0, utils_1.categorizeBrowser)(ua.getBrowser().name);
        var country = (0, utils_1.categorizeCountryWithMap)(__1.globalSettings.consent_country_code);
        var property_id = __1.globalSettings.property_id;
        // Check traffic percentage
        var traffic_percentage = __1.globalSettings.traffic_shaping.traffic_percentage || 1;
        if (traffic_percentage < 1 && Math.random() > traffic_percentage) {
            __1.globalSettings.traffic_shaping.enabled = false;
        }
        if (__1.globalSettings.traffic_shaping.enabled === false) {
            this.ready = true;
            return;
        }
        // fetch flooring data from server
        fetch("https://".concat(envVars_1.endpoint_prefix, "ship.bigcrunch.com/shaping/").concat(property_id, "-").concat(device, "-").concat(browser, "-").concat(country, ".json?rev=").concat(__1.VERSION)).then(function (response) {
            if (response.ok) {
                return response.json();
            }
        }).then(function (data) {
            if (data && data.slots) {
                _this.slotBidderScores = data;
                // fire custom event
                // const event = new CustomEvent('BcDynamicBidderScores', { detail: this.slotBidderScores });
                // document.dispatchEvent(event);
            }
            _this.ready = true;
        });
    };
    /**
     * Selects up to 10 bidders for an auction:
     *  - Always include bidders with score > 0.80.
     *  - Exclude bidders with score < 0.20.
     *  - Randomly select the remaining bidders (weighted by their score) to fill up to 10.
     *
     * @param slot - The name of the slot.
     * @param auctionData - The auction data object.
     * @returns An array of bidder names.
     */
    TrafficShaping.prototype.getBiddersForAuction = function (slot, enabled_bidders) {
        var _a, _b, _c, _d;
        try {
            if (!this.ready || !((_b = (_a = this.slotBidderScores) === null || _a === void 0 ? void 0 : _a.slots) === null || _b === void 0 ? void 0 : _b[slot.name])) {
                return enabled_bidders;
            }
            var maxBidders = ((_c = __1.globalSettings === null || __1.globalSettings === void 0 ? void 0 : __1.globalSettings.traffic_shaping) === null || _c === void 0 ? void 0 : _c.max_bidders) || 5;
            if (!maxBidders) {
                return [];
            }
            var slotData_1 = (_d = this.slotBidderScores) === null || _d === void 0 ? void 0 : _d.slots[slot.name];
            // Filter out bidders that are not in the enabled_bidders list
            var weightedCandidates_1 = [];
            Object.keys(slotData_1).forEach(function (bidder) {
                if (enabled_bidders.includes(bidder)) {
                    var score = slotData_1[bidder];
                    weightedCandidates_1.push({ bidder: bidder, score: score });
                }
            });
            var selectedWeighted = [];
            // If we have remaining spots, selectbidders from weightedCandidates.
            if (weightedCandidates_1.length > 0) {
                // Perform weighted random selection without replacement.
                for (var i = 0; i < maxBidders && weightedCandidates_1.length > 0; i++) {
                    // Calculate total weight of the candidates.
                    var totalWeight = weightedCandidates_1.reduce(function (sum, candidate) { return sum + candidate.score; }, 0);
                    // Generate a random number between 0 and totalWeight.
                    var randomNum = Math.random() * totalWeight;
                    var cumulative = 0;
                    var selectedIndex = 0;
                    // Find the candidate corresponding to the random number.
                    for (var j = 0; j < weightedCandidates_1.length; j++) {
                        cumulative += weightedCandidates_1[j].score;
                        if (randomNum < cumulative) {
                            selectedIndex = j;
                            break;
                        }
                    }
                    // Add the selected bidder and remove it from the pool.
                    selectedWeighted.push(weightedCandidates_1[selectedIndex].bidder);
                    weightedCandidates_1.splice(selectedIndex, 1);
                }
            }
            // If there are fewer than maxBidders eligible bidders, return what we have.
            return selectedWeighted.slice(0, maxBidders);
        }
        catch (error) {
            console.error('Error in getBiddersForAuction', error);
            return enabled_bidders;
        }
    };
    return TrafficShaping;
}());
exports.TrafficShaping = TrafficShaping;
exports.globalTrafficShaping = new TrafficShaping();
