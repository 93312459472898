"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSENT_RULES = exports.isTransmitUfpdConsentDenied = void 0;
exports.isBasicConsentDenied = isBasicConsentDenied;
exports.sensitiveNoticeIs = sensitiveNoticeIs;
exports.isConsentDenied = isConsentDenied;
exports.isTransmitGeoConsentDenied = isTransmitGeoConsentDenied;
exports.flatSection = flatSection;
var activities_1 = require("./activities");
// default interpretation for MSPA consent(s):
// https://docs.prebid.org/features/mspa-usnat.html
var SENSITIVE_DATA_GEO = 7;
function isApplicable(val) {
    return val != null && val !== 0;
}
function isBasicConsentDenied(cd) {
    // service provider mode is always consent denied
    return ['MspaServiceProviderMode', 'Gpc'].some(function (prop) { return cd[prop] === 1; }) ||
        // you cannot consent to what you were not notified of
        cd.PersonalDataConsents === 2 ||
        // minors 13+ who have not given consent
        cd.KnownChildSensitiveDataConsents[0] === 1 ||
        // minors under 13 cannot consent
        isApplicable(cd.KnownChildSensitiveDataConsents[1]) ||
        // covered cannot be zero
        cd.MspaCoveredTransaction === 0;
}
function sensitiveNoticeIs(cd, value) {
    return ['SensitiveDataProcessingOptOutNotice', 'SensitiveDataLimitUseNotice'].some(function (prop) { return cd[prop] === value; });
}
function isConsentDenied(cd) {
    return isBasicConsentDenied(cd) ||
        ['Sale', 'Sharing', 'TargetedAdvertising'].some(function (scope) {
            var oo = cd["".concat(scope, "OptOut")];
            var notice = cd["".concat(scope, "OptOutNotice")];
            // user opted out
            return oo === 1 ||
                // opt-out notice was not given
                notice === 2 ||
                // do not trust CMP if it signals opt-in but no opt-out notice was given
                (oo === 2 && notice === 0);
        }) ||
        // no sharing notice was given ...
        cd.SharingNotice === 2 ||
        // ... or the CMP says it was not applicable, while also claiming it got consent
        (cd.SharingOptOut === 2 && cd.SharingNotice === 0);
}
exports.isTransmitUfpdConsentDenied = (function () {
    // deny anything that smells like: genetic, biometric, state/national ID, financial, union membership,
    // or personal communication data
    var cannotBeInScope = [6, 7, 9, 10, 12].map(function (el) { return --el; });
    // require consent for everything else (except geo, which is treated separately)
    var allExceptGeo = Array.from(Array(12).keys()).filter(function (el) { return el !== SENSITIVE_DATA_GEO; });
    var mustHaveConsent = allExceptGeo.filter(function (el) { return !cannotBeInScope.includes(el); });
    return function (cd) {
        return isConsentDenied(cd) ||
            // no notice about sensitive data was given
            sensitiveNoticeIs(cd, 2) ||
            // extra-sensitive data is applicable
            cannotBeInScope.some(function (i) { return isApplicable(cd.SensitiveDataProcessing[i]); }) ||
            // user opted out for not-as-sensitive data
            mustHaveConsent.some(function (i) { return cd.SensitiveDataProcessing[i] === 1; }) ||
            // CMP says it has consent, but did not give notice about it
            (sensitiveNoticeIs(cd, 0) && allExceptGeo.some(function (i) { return cd.SensitiveDataProcessing[i] === 2; }));
    };
})();
function isTransmitGeoConsentDenied(cd) {
    var geoConsent = cd.SensitiveDataProcessing[SENSITIVE_DATA_GEO];
    return geoConsent === 1 ||
        isBasicConsentDenied(cd) ||
        // no sensitive data notice was given
        sensitiveNoticeIs(cd, 2) ||
        // do not trust CMP if it says it has consent for geo but didn't show a sensitive data notice
        (sensitiveNoticeIs(cd, 0) && geoConsent === 2);
}
exports.CONSENT_RULES = (_a = {},
    _a[activities_1.ACTIVITY_SYNC_USER] = isConsentDenied,
    _a[activities_1.ACTIVITY_ENRICH_EIDS] = isConsentDenied,
    _a[activities_1.ACTIVITY_ENRICH_UFPD] = exports.isTransmitUfpdConsentDenied,
    _a[activities_1.ACTIVITY_TRANSMIT_PRECISE_GEO] = isTransmitGeoConsentDenied,
    _a);
function flatSection(subsections) {
    if (subsections == null || !Array.isArray(subsections))
        return subsections;
    return subsections.reduceRight(function (subsection, consent) {
        return Object.assign(consent, subsection);
    }, {});
}
