"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activityTracker = exports.ActivityTracker = void 0;
var Analytics_1 = require("./Analytics");
var Page_1 = require("./Page");
var ActivityTracker = /** @class */ (function () {
    function ActivityTracker() {
        var _this = this;
        this.lastPageStartTime = new Date().getTime();
        this.lastActivityTime = new Date().getTime();
        this.timeOnPage = 0;
        this.activeTime = 0;
        this.userActive = true;
        this.hiddenTabTimer = null;
        this.activeCheckInterval = this.setInactiveCheck();
        this.activeListenersEnabled = false;
        this.engagementDataSent = false;
        this.setActiveListeners();
        // Monitor tab active state
        document.addEventListener("visibilitychange", (function () {
            // Tab has become visible
            if (document.visibilityState === "visible") {
                // Clear hidden tab timer
                _this.clearTabHiddenTimer();
                // Set last page start time
                _this.lastPageStartTime = new Date().getTime();
                // If engagement data has been sent, update last activity time
                if (_this.engagementDataSent === true) {
                    _this.lastActivityTime = new Date().getTime();
                    _this.engagementDataSent = false;
                }
                // Set active listeners
                _this.setActiveListeners();
                // Tab has become hidden
            }
            else if (document.visibilityState === "hidden") {
                // Update time on page
                _this.timeOnPage += new Date().getTime() - _this.lastPageStartTime;
                // Set user as inactive
                _this.userActive = false;
                // Remove active listeners
                _this.removeActiveListeners();
                // Clear inactive check
                _this.clearInactiveCheck();
                // Set hidden tab timer
                _this.hiddenTabTimer = _this.setTabHiddenTimer();
            }
        }).bind(this));
        // Monitor pagehide event
        window.addEventListener('pagehide', (function () {
            // If tab is active, update time on page
            if (Page_1.globalPage.isTabActive) {
                _this.timeOnPage += new Date().getTime() - _this.lastPageStartTime;
            }
            // If engagement data has not been sent, send it
            if (_this.engagementDataSent === false) {
                _this.engagementDataSent = true;
                Analytics_1.globalAnalytics.sendPageEngagementData();
            }
        }).bind(this));
    }
    ActivityTracker.prototype.trackActivity = function () {
        if (this.userActive === true) {
            this.activeTime += new Date().getTime() - this.lastActivityTime;
        }
        else {
            this.userActive = true;
        }
        this.lastActivityTime = new Date().getTime();
        if (this.activeCheckInterval === null) {
            this.activeCheckInterval = this.setInactiveCheck();
        }
    };
    ActivityTracker.prototype.setInactiveCheck = function () {
        var _this = this;
        return setInterval(function () {
            if (new Date().getTime() - _this.lastActivityTime > 30000) { // 30 seconds of inactivity
                _this.userActive = false;
                _this.clearInactiveCheck();
            }
        }, 1000);
    };
    ActivityTracker.prototype.clearInactiveCheck = function () {
        if (this.activeCheckInterval) {
            clearInterval(this.activeCheckInterval);
            this.activeCheckInterval = null;
        }
    };
    ActivityTracker.prototype.setTabHiddenTimer = function () {
        var _this = this;
        return setTimeout(function () {
            if (_this.engagementDataSent === false) {
                _this.engagementDataSent = true;
                Analytics_1.globalAnalytics.sendPageEngagementData();
            }
        }, 300000);
    };
    ActivityTracker.prototype.clearTabHiddenTimer = function () {
        if (this.hiddenTabTimer) {
            clearTimeout(this.hiddenTabTimer);
            this.hiddenTabTimer = null;
        }
    };
    ActivityTracker.prototype.setActiveListeners = function () {
        if (this.activeListenersEnabled)
            return;
        this.activeListenersEnabled = true;
        // Track various user activities
        window.addEventListener('mousemove', this.trackActivity.bind(this));
        window.addEventListener('click', this.trackActivity.bind(this));
        window.addEventListener('scroll', this.trackActivity.bind(this));
        window.addEventListener('keydown', this.trackActivity.bind(this));
    };
    ActivityTracker.prototype.removeActiveListeners = function () {
        if (!this.activeListenersEnabled)
            return;
        this.activeListenersEnabled = false;
        window.removeEventListener('mousemove', this.trackActivity.bind(this));
        window.removeEventListener('click', this.trackActivity.bind(this));
        window.removeEventListener('scroll', this.trackActivity.bind(this));
        window.removeEventListener('keydown', this.trackActivity.bind(this));
    };
    ActivityTracker.prototype.sendAndResetData = function () {
        if (this.engagementDataSent === false) {
            Analytics_1.globalAnalytics.sendPageEngagementData();
        }
        this.engagementDataSent = false;
        this.lastPageStartTime = new Date().getTime();
        this.lastActivityTime = new Date().getTime();
        this.timeOnPage = 0;
        this.activeTime = 0;
        this.userActive = true;
        this.clearTabHiddenTimer();
        this.clearInactiveCheck();
        this.activeCheckInterval = this.setInactiveCheck();
    };
    return ActivityTracker;
}());
exports.ActivityTracker = ActivityTracker;
exports.activityTracker = new ActivityTracker();
